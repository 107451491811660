import { action, computed, observable } from 'mobx';
import QuizItemSettings from './QuizItemSettings';

export type SelectListItemOption = {
    key: string;
    label: string;
    imageUrl?: string;
}

export class SelectListItemSettings extends QuizItemSettings {
    constructor(s?: Partial<SelectListItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @observable optionType: 'default' | 'button';
    /**
     * should navigate to the next page according the rule without Next click
     */
    @observable goToNextPage: boolean;
    @observable options: SelectListItemOption[];

    @computed get hasImage() {
        return this.options.some(x => x.imageUrl != undefined);
    }

    @action update(q: Partial<SelectListItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(def?: Partial<SelectListItemSettings>) {
        return new SelectListItemSettings({
            ...QuizItemSettings.default,
            options: [],
            optionType: 'default',
            goToNextPage: false,
            ...def
        });
    }

    clone() {
        return new SelectListItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return SelectListItemSettings.build(json);
    }
}
