import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { BaseFilter, Entity } from '..';
import { Lang } from '../../enums';
import { ISerializable } from '../Entity';

export default class Announcement extends Entity implements ISerializable {
    constructor(announcement?: Partial<Announcement>) {
        super();
        if (announcement) this.update(announcement);
    }

    @observable name: string;
    @observable text: string;

    @observable lang: Lang;
    @observable isAdmin: boolean;
    @observable isSchool: boolean;

    @observable createdDate: moment.Moment;
    @observable beginDate: moment.Moment;
    @observable endDate: moment.Moment;
    @observable updatedDate: moment.Moment;
    @observable isPublished: boolean;

    @computed get isActive() {
        return moment().isBetween(this.beginDate, this.endDate);
    }

    @computed get validDays() {
        return this.beginDate.diff(this.endDate, 'days');
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            text: this.text,
            lang: this.lang,
            isAdmin: this.isAdmin,
            isSchool: this.isSchool,
            isPublished: this.isPublished,
            beginDate: this.beginDate?.toISOString(),
            endDate: this.endDate?.toISOString(),
        }
    }

    clone() {
        return new Announcement({
            ...this
        });
    }

    @action update(announcement: Partial<Announcement>, allowUndefined = false) {
        super.update(announcement, allowUndefined);
    }

    static fromJson(json: any): Announcement {
        return new Announcement({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            beginDate: json.beginDate ? moment(json.beginDate) : undefined,
            endDate: json.endDate ? moment(json.endDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined
        });
    }
}

export class AnnouncementFilter extends BaseFilter<Announcement> {
    constructor(filter?: Partial<AnnouncementFilter>) {
        super();
        if (filter) this.update(filter);
    }

    id: string;
    excludeId: string;
    name: string;
    isActive: boolean;
    isAdmin: boolean;
    isSchool: boolean;
    isPublished: boolean;
    lang: Lang;

    update(changes: Partial<AnnouncementFilter>) {
        super.update(changes);
    }
}
