import { observable, runInAction } from "mobx";
import { isEmpty } from "../../../utils";
import BaseChannelSettings from "./BaseChannelSettings";
import Entity from "../../Entity";
import { throws } from "assert";

export enum Wazzup24ChatType {
    WhatsApp = 'whatsapp',
    WAPI = 'wapi',
    Instagram = 'instagram',
    Telegram = 'telegram',
    TgAPI = 'tgapi',
    Vk = 'vk'
}

export class Wazzup24Channel extends Entity {
    constructor(channel?: Partial<Wazzup24Channel>) {
        super();
        if (channel) this.update(channel);
    }

    @observable name: string;
    @observable plainId: string;
    @observable chatType: Wazzup24ChatType;

    static fromJson(json: any): Wazzup24Channel {
        return new Wazzup24Channel({
            ...json,
        });
    }

    update(channel: Partial<Wazzup24Channel>) {
        super.update(channel);
    }
}

export default class Wazzup24ChannelSettings extends BaseChannelSettings {
    @observable channelName: string;
    @observable channelId: string;
    @observable plainId: string;
    @observable chatType: Wazzup24ChatType;

    constructor(settings?: Partial<Wazzup24ChannelSettings>) {
        super();
        if (settings) this.update(settings);
    }

    get channel(): Wazzup24Channel | null {
        if (!this.hasChannel) return null;
        return new Wazzup24Channel({
            id: this.channelId,
            name: this.channelName,
            plainId: this.plainId,
            chatType: this.chatType,
        });
    }

    set channel(channel: Wazzup24Channel | null) {
        if (!channel) return; // ignore this case
        runInAction(() => {
            this.channelName = channel.name;
            this.channelId = channel.id;
            this.plainId = channel.plainId;
            this.chatType = channel.chatType;
        });
    }

    get isValid() {
        return !isEmpty(this.accessToken) && this.hasChannel;
    }

    get hasChannel() {
        return !isEmpty(this.channelId) && !isEmpty(this.plainId);
    }

    update(entity: Partial<Wazzup24ChannelSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    hasChanges(settings: Wazzup24ChannelSettings) {
        return super.hasChanges(settings)
            || this.channelId != settings.channelId
            || this.plainId != settings.plainId
            || this.channelName != settings.channelName
            || this.chatType != settings.chatType;
    }

    clone() {
        return new Wazzup24ChannelSettings(this);
    }
}