export enum ErrorCode {
    //#region Global 0-1000
    InvalidModel = 0,
    UserNotFound = 1,
    InternalError = 2,
    MustSpecifyFieldsToSelect = 3,
    IsRequired = 4,
    TokenExpired = 5,
    AccessDenied = 6,
    FileNotFound = 7,
    NeedFillField = 8,
    EditingProhibited = 9,
    LoginFailed = 10,
    RequestLogLevelFailed = 11,
    UnknownTimezone = 12,
    NumberAttemptsExceeded = 13,
    EmailAlreadyConfirmed = 14,
    AlreadyExists = 15,
    EmailAlreadyExists = 16,
    InvalidDatePeriod = 17,
    FailedSendEmail = 18,
    InvalidEmail = 19,
    EmailNotConfirmed = 20,
    EmailNotFound = 21,
    AlreadyHasDifferentWebhook = 27,
    AlreadyHasBeenChanged = 31,
    InvalidParameter = 1000,
    //#endregion

    //#region Authorization 1001-2000
    WrongPassword = 1001,
    PasswordMustBeBetween6And30Characters = 1002,
    PasswordAndConfirmationPasswordDoNotMatch = 1003,
    InvalidRefreshToken = 1005,
    UserAlreadyExist = 1006,
    InvalidAccessToken = 1007,
    InvalidResetToken = 1008,
    SchoolNotFound = 1009,
    CannotDeleteOwnerFromSchool = 1010,
    SchoolPhoneInsufficientFunds = 1011,
    LockedSchoolAccessDenied = 1012,
    LockedUserAccessDenied = 1013,
    ConfirmCodeSendExceedLimit = 1014,
    InvalidConfirmCode = 1015,
    NewPasswordMustBeDifferent = 1016,
    ResetPasswordRequired = 1017,
    //#endregion

    //#region Localization 2001 - 3000
    LocWordAlreadyExist = 2001,
    LocWordNotFound = 2002,
    LocTranslationNotFound = 2003,
    LocNeedFillFields = 2004,
    LocLanguageNotFound = 2005,
    LocWordNeedText = 2006,
    LocWordNeedTranslations = 2007,
    LocTranslationNeedText = 2008,
    LocNeedFields = 2009,
    //#endregion

    //#region EmailTemplate 3001 - 4000
    EmailTemplateNotFound = 3001,
    //#endregion

    //#region User 4001 - 5000
    PhoneAlreadyExist = 4001,
    InvalidPhone = 4002,
    //#endregion

    //#region School 5001 - 6000
    SchoolAlreadyExist = 5001,
    SchoolAdminNotFound = 5002,
    SchoolProfileSettingsNotFound = 5003,
    SchoolStatisticsNotFound = 5101,
    SchoolBillingNotFound = 5201,
    SchoolDomainAlreadyExist = 5251,
    //#endregion

    //#region Course 6001-7000
    CourseNotFound = 6001,
    CoursePlanNotFound = 6002,
    CourseCategoryNotFound = 6003,
    CourseAlreadyContainsPackage = 6005,
    CourseNotFoundPackageLink = 6006,
    CourseCategoryRequireName = 6007,
    CourseAlreadyContainCategory = 6008,
    CourseNotContainCategory = 6009,
    CourseItemNotFound = 6010,
    CourseFileBelongsToAnotherCourse = 6011,
    CourseItemNotValidPosition = 6013,
    CourseItemAlreadyContainsPlan = 6014,
    CourseItemNotFoundPlanLink = 6015,
    CourseItemCommentNotFound = 6016,
    CourseItemTaskNotFound = 6016,
    CourseItemTestQuestionNotFound = 6017,
    CourseItemTestAnswerNotFound = 6018,
    CourseItemTestNotFound = 6019,
    CourseStudentNotFound = 6020,
    CourseItemTestQuestionMustHaveCorrectAnswer = 6021,
    CourseItemTaskFieldNotFound = 6027,
    //#endregion

    // #region File 7001-8000
    FileInitiateError = 7001,
    FileUploadError = 7002,
    FileCompleteError = 7003,
    DirectoryWithSameNameAlreadyExist = 7004,
    DirectoryCannotBeRecursive = 7005,
    DirectoryNotFound = 7006,
    FileManagerItemNotFound = 7007,
    YouCannotDeleteDirectoryBecauseIsNotEmpty = 7008,
    FileWithSameNameAlreadyExist = 7009,
    FileUpdateCannedACLError = 7010,
    FileReplaceExtensionError = 7011,
    FileUpdateError = 7012,
    // #endregion

    // #region Student 8001 - 9000
    StudentNotFound = 8001,
    StudentCourseNotFound = 8002,
    StudentCourseItemProgressNotFound = 8003,
    StudentCourseItemTaskAnswerNotFound = 8004,
    StudentCourseStepAccessDenied = 8005,
    StudentCourseCannotBeCompleted = 8006,
    StudentCourseItemTaskAnswerCommentNotFound = 8007,
    StudentCourseItemCommentTimeLimit = 8009,

    StudentCourseStartError = 8010,
    StudentNotHaveEmail = 8011,
    StudentAlreadyMerging = 8012,

    StudentLibraryItemsNotFound = 8013,
    StudentCourseHasRequirements = 8040,

    StudentCourseItemsNotFound = 8500,
    // #endregion

    // #region UserInvite 9001 - 10000
    InvalidInviteToken = 9001,
    // #endregion

    // #region Tag 10001 - 11000
    TagNotFound = 10001,
    TagAlreadyExists = 10002,
    // #endregion

    // #region StudentTagLink 11001 - 12000
    StudentTagLinkNotFound = 11001,
    // #endregion

    // #region CRM 12001 - 13000
    LeadNotFound = 12001,
    StudentLicenseNotFound = 12003,

    LeadAttributeNotFound = 12100,
    LeadAttributeNameAlreadyExists = 12101,
    LeadAttributeKeyNameAlreadyExists = 12102,
    LeadAttributeKeyNameBusyBySystem = 12103,
    // #endregion

    // #region Flow 13001 - 14000
    FlowNotFound = 13001,
    FlowDateNotFound = 13002,
    // #endregion

    // #region Admin 14001 - 15000
    AdminNotFound = 14001,
    AdminResponsibleNotFound = 14002,
    AdminResponsibleFromUserExists = 14011,
    AdminCRMSettingsNotFound = 14050,
    AdminPartnerTariffNotFound = 14100,
    AdminPartnerTariffAlreadyExists = 14101,
    AdminPartnerTransactionNotFound = 14200,
    AdminPartnerTransactionInsufficientFunds = 14201,
    // #endregion

    // #region CRMImport 15001 - 16000
    // Couldnt read file
    CRMImportInvalidFile = 15001,
    // Couldnt parse file
    CRMImportInvalidText = 15002,
    CRMImportNoRowsFound = 15003,
    CRMImportError = 15004,
    // Couldnt read row
    CRMImportInvalidRow = 15005,
    // Field does not satisfy the pattern
    CRMImportFieldInvalidPattern = 15006,
    // Email is required fields
    CRMImportEmailRequired = 15007,
    // Failed to cast the value to the desired type
    CRMImportInvalidFieldCast = 15008,
    CRMImportRequiredFields = 15012,
    // #endregion


    //#regin Forum
    ForumThreadNotFound = 17001,
    ForumThreadCannotDeleteWhileHasNestedThreads = 17002,
    ForumAccessDenied = 17003,
    ForumThreadModeratorNotFound = 17100,
    ForumTopicNotFound = 17200,
    ForumTopicCommentNotFound = 17201,
    ForumReactionNotFound = 17300,
    ForumSubscriptionNotFound = 17400,
    ForumUnableCreateTopic = 17451,
    ForumUnableEditTopic = 17452,
    ForumUnableEditTopicComment = 17453,
    ForumUnableCreateTopicTooOften = 17454,
    ForumBanNotFound = 17800,
    ForumBanAccessDenied = 17801,
    //#endregin


    // #region Chat 18001 - 19000
    ChatConversationNotFound = 18001,
    ChatCurrentContactNotFound = 18002,
    ChatContactNotFound = 18003,
    ChatDepartmentNotFound = 18004,
    ChatConversationMemberNotFound = 18005,
    ChatMessageNotFound = 18006,
    ChatMessageCouldntBeEmpty = 18007,
    ChatConversationWrongType = 18008,
    ChatConversationWrongContactCount = 18009,
    // #endregion

    // #region Email profile 19001-20000
    EmailProfileNotFound = 19001,
    EmailProfileFailedDeletePrimary = 19002,
    EmailProfileFailedDeleteActive = 19003,
    EmailProfileTestFailed = 19004,
    // #endregion

    // #region Extended filter 20001 - 21000
    ExtendedFilterNotFound = 20001,
    // #endregion

    // #region Email domain 21001 - 22000
    EmailDomainNotFound = 21001,
    EmailDomainFailedDelete = 21002,
    EmailDomainFailedAdd = 21003,
    EmailDomainFailedVerify = 21004,
    EmailDomainNeedVerify = 21005,
    EmailDomainWaitRegistration = 21007,
    // #endregion

    //  #region Email message 25001-26000
    EmailMessageNotFound = 25001,
    EmailMessageBlockedEmails = 25002,
    EmailMessageNotEnoughDataToSend = 25003,
    EmailMessageAttachmentsSizeExceeded = 25004,
    EmailMessageExceedLimit = 25006,
    //    #endregion

    // #region Calls 27001-28000
    CallAccountAlreadyInUse = 27015,
    CallAccountCantSetWebhookUrl = 27016,
    // #endregion

    // #region Library 29001-30000
    LibraryNotFound = 29001,
    LibraryItemNotFound = 29501,
    LibraryItemAccessDenied = 29502,
    // #endregion

    // #region Product package 30001-31000
    ProductPackageNotFound = 30001,
    // #endregion

    // #region Site 31001-32000
    SiteNotFound = 31001,
    SiteAlreadyExists = 31002,
    SitePageNotFound = 31003,
    SitePageAlreadyExists = 31004,
    SiteDomainNotFound = 31005,
    SiteDomainAlreadyExists = 31006,
    SiteMenuItemAlreadyExists = 31007,
    SiteMenuItemNotFound = 31008,
    // #endregion

    // #region Product 33001-34000
    ProductNotFound = 33001,
    ProductImageLinkNotFound = 33002,
    ProductContentAlreadyExists = 33100,
    // #endregion

    WebinarNotFound = 38001,
    WebinarShortNameAlreadyExist = 38003,
    WebinarScheduleInvalid = 38004,
    WebinarImportEventInvalidFileFormat = 38451,
    WebinarImportEventInvalidEventFormat = 38452,

    // #region Chat channel 41001-42000
    ChatChannelNotFound = 41001,
    ChatChannelResponseTimeLimitExceeded = 41002,
    // #endregion

    BulkActionRegisterWebinarMaxSizeExceeded = 44001,

    // #region Document 45001-46000
    DocumentNotFound = 45001,
    DocumentContentNotFound = 45100,
    DocumentUrlAlreadyExists = 45200,
    DocumentTitleAlreadyExists = 45300,
    // #endregion


    // #region Billing 49001-50000
    BillingPlanNotFound = 49001,
    BillingPlanNameAlreadyExists = 49002,
    BillingPaymentMethodNotFound = 49100,
    BillingPaymentNotFound = 49200,
    BillingPaymentFailed = 49201,
    BillingInvoiceNotFound = 49301,
    BillingCurrencyExchangeRateChanged = 49401,
    // #endregin

    // #region Requisite  52001-53000
    RequisiteNotFound = 52001,
    RequisiteAlreadyExists = 52002,
    // #endregion

    //#region Pipeline 53001 - 54000
    PipelineNotFound = 53001,
    PipelineStageNotFound = 53050,
    PipelineStageItemNotFound = 53100,
    PipelineTagNotFound = 53150,
    PipelineFieldNotFound = 53200,
    PipelineFieldValueUpdatedFailed = 53201,
    PipelineFieldKeyAlreadyExists = 53202,
    PipelineFieldRequired = 53203,
    PipelineItemNotFound = 53301,
    //#endregion

    //#region Application  54001-55000
    ApplicationNotFound = 54001,
    ApplicationSchoolLinkNotFound = 54101,
    ApplicationSchoolLinkCantBeRestored = 54102,
    TelegramGroupNotFound = 54201,
    TelegramGroupCantRegistered = 54202,
    TelegramGroupUserNotFound = 54301,
    //#endregion

    //#region Glossary  55001 - 56000
    GlossaryNotFound = 55001,
    GlossaryAlreadyExists = 55002,
    GlossaryWordsCountExceeded = 55003,
    //#endregion

    // #region Global Variable 56001-57000
    GlobalVariableNotFound = 56001,
    // #endregion

    // #region Webhook 57001-58000
    WebhookNumberAttemptsExceeded = 57001,
    // #endregion

    // #region Partnership 58001-59000
    PartnershipNotFound = 58001,
    PartnershipAlreadyExists = 58002,
    PartnershipInsufficientFunds = 58003,
    PartnershipTransferMinFeeAmountRestrictions = 58004,
    PartnershipMemberNotFound = 58050,
    PartnershipTransactionNotFound = 58100,
    PartnershipOfferNotFound = 58200,
    PartnershipOfferWithSameProductAlreadyExists = 58201,
    PartnershipTransactionRequestNotFound = 58251,
    // #endregion

    // #region Shared Settings 59001-60000
    SharedSettingsExportNotFound = 59001,
    SharedSettingsReexportProhibited = 59002,
    SharedSettingsImportNotFound = 59100,
    // #endregion
}