import { Lang } from './../enums/lang';
import { observable, action, runInAction } from 'mobx';
import { inject } from 'react-ioc';
import Api from '../api';
import { FetchLocalizationRequest } from '../api/methods/loc/types/fetch';

const LocalizationStorageKey = 'localization';

type LocalizationWords = {
    key: string;
    value: string;
}[];

type LocalizationCache = {
    words: LocalizationWords;
    timestamp: number;
    lang: Lang;
}

export default class LocalizationStore {
    @observable ready: boolean = false;
    @observable words: LocalizationWords = [];
    lang: Lang;
    timestamp?: number;

    constructor(private api: Api) {
        this.api = api || inject(this, Api);
    }

    get cache(): LocalizationCache | null {
        var locCacheJson = localStorage.getItem(LocalizationStorageKey);
        if (locCacheJson != null)
            return JSON.parse(locCacheJson) as LocalizationCache;
        return null;
    }

    set cache(cache: LocalizationCache | null) {
        if (cache == null) {
            localStorage.removeItem(LocalizationStorageKey);
            return;
        }
        localStorage.setItem(LocalizationStorageKey, JSON.stringify(cache));
    }

    async init(lang: Lang) {
        const locCache = this.cache;
        // only if desired lang and cached lang are the same 
        if (locCache?.lang == lang)
            this.setData(lang, locCache.words, locCache.timestamp, false);
        else this.clear(false);

        await this.fetchWords(lang);
    }

    @action clear(cache = true) {
        this.words = [];
        this.timestamp = undefined;
        this.ready = false;
        if (cache)
            this.cache = null;
    }

    @action private async fetchWords(lang: Lang) {
        const result = await this.api.loc.fetch(new FetchLocalizationRequest(lang, this.timestamp));
        if (!result.success)
            return;
        if (result.response.needSync)
            this.setData(lang, result.response.words, result.response.timestamp);
        runInAction(() => this.ready = true);
    }

    @action private async setData(lang: Lang, words: LocalizationWords, timestamp: number, needCache = true) {
        this.lang = lang;
        this.words = words;
        this.timestamp = timestamp;
        if (needCache)
            this.cache = { timestamp, words, lang };
    }
}