import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Tooltip } from 'antd';
import styles from './Comment.module.scss';
import { AccelFile } from '../../models';
import { Files, LinkifyText, UserAvatar, useClipboardFiles } from '..';
import { Context } from '../AccelProvider/AccelProvider';
import { SendOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import { action } from 'mobx';
import { combineClasses, isEmpty } from '../../utils';
import EmojiInput from './components/EmojiInput/EmojiInput';
import FileInput from './components/FileInput/FileInput';
import Editor, { EditorHandler } from './components/Editor/Editor';
import VoiceInput from "./components/VoiceInput/VoiceInput";
import { VoiceItem, VoiceList } from "./components/VoiceList/VoiceList";
import { CommentFormHandler, CommentFormProps } from "./CommentForm";

/**
 * Comment editor form that supports text/emoji/files, voice recording, etc.
 */
export const CommentFormEditor: React.FC<CommentFormProps> = observer((props) => {
    const ctx = useContext(Context);
    const { loc } = ctx;
    const editorRef = useRef<EditorHandler>();
    const voiceInputHandler = VoiceInput.useHandler();

    const [handler] = useState<CommentFormHandler>({
        focus: () => editorRef.current?.focus(),
        blur: () => editorRef.current?.blur(),
        setText: (text: string) => {
            store.setText(text, true);
        },
        insertText: (text: string) => {
            editorRef.current?.insert(text);
        },
        setFiles: (files: AccelFile[]) => {
            store.setFiles(files, true);
        },
        setVoices: (files: AccelFile[]) => {
            store.setVoices(files);
        },
        hasUploadingFiles: () => {
            return store.files.some(x => !x.isUploaded);
        },
        cancelVoiceRecording: () => {
            voiceInputHandler.current?.cancelRecording();
        }
    });

    useEffect(() => {
        if (props.handlerRef)
            props.handlerRef.current = handler;
    }, []);

    useEffect(() => {
        store.setSendHandler(props.onSend);
    }, [props.onSend]);

    const store = useLocalStore(() => ({
        text: props.text ?? props.defaultText ?? '',
        setText: action((text: string, force = false) => {
            if (!props.text || force) {
                store.text = text;
                props.onTextChange?.(store.text);
            }
        }),
        files: (props.files ?? props.defaultFiles ?? []).slice(),
        setFiles: action((files: AccelFile[], force = false) => {
            if (!props.files || force) {
                store.files = files.slice();
            }
        }),
        voices: (props.voices ?? props.defaultVoices ?? []).slice() as AccelFile[],
        setVoices: action((files: AccelFile[]) => {
            store.voices = files;
        }),
        addVoice: action((file: AccelFile) => {
            store.setVoices([...store.voices, file]);
            props.onVoicesChange?.(store.voices, {
                added: [file]
            });
        }),
        removeVoice(file: AccelFile) {
            store.setVoices(store.voices.filter(x => x.id != file.id || x != file));
            props.onVoicesChange?.(store.voices, {
                deleted: [file]
            });
        },
        clearVoices: action(() => {
            const prev = store.voices.slice();
            store.voices = [];
            props.onVoicesChange?.(store.voices, {
                deleted: prev
            });
        }),
        get trimmedText() {
            return store.text.trim();
        },
        sending: false,
        setSending: action((sending: boolean) => {
            store.sending = sending;
        }),
        _disabled: props.disabled ?? false,
        setDisabled: action((_disabled: boolean) => {
            store._disabled = _disabled;
        }),
        get isMaxLengthReached() {
            if (!props.maxLength) return false;
            return store.trimmedText.length > props.maxLength;
        },
        get canSend() {
            if (store.isMaxLengthReached) return false;
            return store.hasText || store.hasAttachments || store.voices != null;
        },
        get availableFileCount() {
            return props.fileProps?.maxFiles ? Math.max(0, props.fileProps.maxFiles - store.files.length) : null;
        },
        get canAttachFiles() {
            return store.availableFileCount == null || store.availableFileCount > 0
        },
        get disabled() {
            return store._disabled || store.sending;
        },
        get hasText() {
            return !isEmpty(store.trimmedText);
        },
        get hasAttachments() {
            return store.files.length > 0;
        },
        get hasTextOrAttachments() {
            return store.hasText || store.hasAttachments;
        },
        hasFocus: false,
        setFocus: (focus: boolean) => {
            store.hasFocus = focus;
        },
        sendHandler: props.onSend,
        setSendHandler: (handler: typeof props.onSend) => {
            store.sendHandler = handler;
        },
        async send() {
            if (!store.canSend || !store.sendHandler) return false;
            store.setSending(true);
            let filesToSend = store.files.slice();
            if (store.voices.length > 0)
                filesToSend = [...store.voices, ...filesToSend];
            const success = await store.sendHandler(store.trimmedText, filesToSend);
            if (success) {
                if (props.clearAfterSending == true) {
                    store.setFiles([]);
                    store.clearVoices();
                    store.setText('');
                }
            }
            store.setSending(false);
            return success;
        },
        attachFiles(files: AccelFile[]) {
            if (files.length == 0)
                return;
            if (store.availableFileCount != null) {
                if (store.availableFileCount == 0) return;
                // take max images we can upload
                if (files.length > store.availableFileCount)
                    files = files.slice(0, store.availableFileCount);
            }
            store.setFiles([...store.files, ...files]);
            props.onFilesChange?.(store.files, {
                added: files
            });
        },
        deleteFile(file: AccelFile) {
            store.setFiles(store.files.filter(x => x.id != file.id || x != file));
            props.onFilesChange?.(store.files, {
                deleted: [file]
            });
        },
        reorderFiles(src: number, dst: number) {
            store.setFiles(store.files.reorder(src, dst).items);
            props.onFilesChange?.(store.files, {});
        }
    }));

    useClipboardFiles(file => {
        if (!store.hasFocus) return;
        store.attachFiles([AccelFile.fromFile(file)]);
    }, props.useFiles === true, [props.useFiles]);

    useEffect(() => {
        if (props.text === undefined) return;
        store.setText(props.text, true);
    }, [props.text]);

    useEffect(() => {
        if (props.defaultText === undefined) return;
        store.setText(props.defaultText);
    }, [props.defaultText]);

    useEffect(() => {
        if (props.defaultFiles === undefined) return;
        store.setFiles(props.defaultFiles);
    }, [props.defaultFiles]);

    useEffect(() => {
        if (props.defaultVoices === undefined) return;
        store.setVoices(props.defaultVoices);
    }, [props.defaultVoices]);

    useEffect(() => {
        if (props.files === undefined) return;
        store.setFiles(props.files);
    }, [props.files]);

    useEffect(() => {
        if (props.disabled !== undefined)
            store.setDisabled(props.disabled);
    }, [props.disabled]);

    useEffect(() => {
        if (props.voices === undefined) return;
        store.setVoices(props.voices);
    }, [props.voices]);

    const handleRecordingEnded = useCallback((file: AccelFile) => {
        store.addVoice(file);
        props.onVoiceRecordingEnded?.(file);
    }, [props.onVoiceRecordingEnded]);

    const handleRecordingCanceled = useCallback(() => {
        store.setVoices([]);
        props.onVoiceRecordingCanceled?.();
    }, [props.onVoiceRecordingCanceled]);

    const sendButton = props.sendDisabled === true
        ? null
        : <Tooltip title={loc.word('Max text length reached', { default: 'Max text length reached' })}
            open={store.isMaxLengthReached}>
            <Button type='link'
                onClick={() => store.send()}
                disabled={store.disabled || !store.canSend}
                size='large'
                icon={<SendOutlined className={props.iconClassname} />}
                className="comment-msg-btn-send"
            />
        </Tooltip>;

    const voiceInput = <VoiceInput handler={voiceInputHandler}
        deviceId={props.micDeviceId}
        disabled={store.disabled}
        iconClassname={props.iconClassname}
        onRecordingStarted={props.onVoiceRecordingStarted}
        onRecordingEnded={handleRecordingEnded}
        onRecordingCanceled={handleRecordingCanceled} />;

    return <div className={combineClasses(styles.comment_editor_wrapper, 'flex flex-col')} style={props.style}>
        <div className='flex flex-1 w-100'>
            {props.author && <UserAvatar user={props.author} size={40} className='mr-10' />}

            <div className={'flex flex-1 align-center p-2'} data-borderless={props.borderless ?? false}>

                {(store.voices.length > 0 && props.voiceMode !== 'combined')
                    ? <VoiceItem voice={store.voices[0]} audioSize={props.audioPlayerSize}
                        deletable={props.editing === true}
                        downloadable={false}
                        loc={loc}
                        onDelete={() => store.removeVoice(store.voices[0])}
                        suffix={props.editing === true ? sendButton : null}
                        playerStyle={props.audioPlayerStyle} />
                    : props.editing === true
                        ? <div className={combineClasses(props.editorClassName, styles.comment_editor, 'flex flex-1 align-center relative')} style={props.editorStyle}>
                            {props.useFiles &&
                                <FileInput loc={loc}
                                    disabled={store.disabled || !store.canAttachFiles}
                                    maxFiles={props.fileProps?.maxFiles}
                                    forbiddenFileExtensions={props.fileProps?.forbiddenExtensions}
                                    maxFileSize={props.fileProps?.maxFileSize}
                                    onAttach={x => store.attachFiles(x)}
                                    iconClassname={props.iconClassname} />}

                            <Editor handlerRef={editorRef}
                                loc={loc}
                                enterToSubmit={props.enterToSubmit!}
                                text={store.text}
                                autoSize={props.autoSize}
                                disabled={store.disabled}
                                placeholder={props.placeholder}
                                autoFocus={props.autoFocus}
                                onSend={async () => await store.send()}
                                onChange={x => store.setText(x)}
                                className={props.inputClassName}
                                onFocus={() => store.setFocus(true)}
                                onBlur={() => store.setFocus(false)}
                            />
                            <div className='flex align-center'>
                                {props.actionAddons?.(handler)}

                                {props.useEmoji &&
                                    <EmojiInput
                                        onSelect={emoji => editorRef.current?.insert(emoji)}
                                        disabled={store.disabled}
                                        iconClassname={props.iconClassname} />}

                                {props.useVoice === true
                                    ? <>
                                        {props.voiceMode == 'split'
                                            ? store.hasTextOrAttachments
                                                ? sendButton
                                                : voiceInput
                                            : <>
                                                {voiceInput}
                                                {sendButton}
                                            </>
                                        }
                                    </>
                                    : sendButton}
                            </div>
                        </div>
                        : <div>
                            {props.textRenderer?.(store.text) ?? <LinkifyText text={store.text} />}
                        </div>}
            </div>
        </div>
        {(store.voices && props.voiceMode === 'combined') && <div className='mt-10 pl-11 pr-11 overflow-auto w-100'>
            <VoiceList voices={store.voices} audioSize={props.audioPlayerSize} deletable={!store.disabled && props.editing === true} loc={loc} onDelete={x => store.removeVoice(x)} playerStyle={props.audioPlayerStyle} />
        </div>}

        {store.files.length > 0 &&
            <div className='mt-10 pl-11 pr-11 overflow-auto w-100' style={{ maxHeight: 250 }}>
                <Files
                    editable={!store.disabled && props.editing === true}
                    deletable={!store.disabled && props.editing === true}
                    files={store.files}
                    onDrop={(src, dst) => store.reorderFiles(src, dst)}
                    beforeRemove={async x => {
                        store.deleteFile(x);
                        // prevent remove
                        return false;
                    }}
                    draggable={props.fileProps?.draggable}
                    view={props.fileProps?.view || 'grid'}
                    size={props.fileProps?.size || 'md'} />
            </div>}
    </div>;
})

CommentFormEditor.defaultProps = {
    enterToSubmit: true,
    useFiles: true,
    fileProps: {
        view: 'list',
        draggable: false
    },
    useEmoji: true,
    useVoice: false,
    autoSize: { minRows: 2, maxRows: 5 },
    voiceMode: 'split',
    editing: true
}

export default CommentFormEditor;