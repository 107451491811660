import { SortingDirection } from '../enums';
import { isEmpty, toQueryStringFilter, toSelectStringFilter } from '../utils';
import ExtendedFilter from './ExtendedFilter/ExtendedFilter';

export declare type FieldConditionType = 'contains' | 'notContains' | 'equals' | 'notEquals' | 'lessThan' | 'moreThan' | 'specified' | 'notSpecified' | 'range';
export declare type FieldDateConditionType = FieldConditionType | 'today' | 'yesterday' | 'week' | 'lastWeek' | 'month' | 'lastMonth';
export type Field<T = any> = {
    type: string;
    condition: FieldConditionType;
    value: T;
}

export type FieldGroup = {
    fields: Field[];
}

export default class BaseFilter<T> {
    constructor(filter?: Partial<BaseFilter<T>>) {
        if (filter) this.update(filter);
    }

    skip: number = 0;
    take: number = 25;
    itemsTotal: number;
    search?: string;
    sortName?: string;
    /**
     * TODO delete SortingDirection enum later
     */
    sortType?: SortingDirection | 'Asc' | 'Desc';
    filters?: any;
    softDeleted: boolean | null = false;
    useSort: boolean = true;
    useBaseFilter: boolean = true;
    useItemsTotal: boolean = true;
    ids?: string[];
    extendedFilterId?: string;
    extendedFilter?: string;

    get groups(): FieldGroup[] {
        if (isEmpty(this.extendedFilter))
            return [];
        return JSON.parse(this.extendedFilter!);
    }

    set groups(value: FieldGroup[]) {
        if (isEmpty(value))
            this.extendedFilter = undefined;
        this.extendedFilter = JSON.stringify(value);
    }

    get page(): number {
        return (this.skip / Math.max(1, this.take) + 1);
    }

    set page(value: number) {
        this.skip = (value! - 1) * Math.max(1, this.take);
    }

    get pageTotal(): number {
        return Math.ceil(this.itemsTotal / Math.max(1, this.take));
    }

    get selectString(): string {
        return toSelectStringFilter(this);
    }

    get queryString(): string {
        return toQueryStringFilter(this);
    }

    /**
     * 'changes' can contain undefined fields despite other entities
     * @param changes 
     */
    update(changes: Partial<BaseFilter<T>>) {
        for (const key in changes)
            if (changes.hasOwnProperty(key))
                //  all keys in filter must be undefined (not null)
                //@ts-ignore
                this[key] = changes[key] === null ? undefined : changes[key];
        // softDeleted is only exception
        if (changes.softDeleted === null)
            this.softDeleted = null;
    }

    toExtendedFilter() {
        if (this.extendedFilterId || (this.groups && this.groups.length > 0))
            return new ExtendedFilter({ id: this.extendedFilterId, groups: this.groups });
        return null;
    }

    hasChanges(filter: BaseFilter<T>) {
        return this.search != filter.search
            || this.softDeleted != filter.softDeleted
            || this.extendedFilterId != filter.extendedFilterId
            || this.extendedFilter != filter.extendedFilter;
    }
}